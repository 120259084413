body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.site-container {
  text-align: center;
}
html {
  font-size: 14px;
}
body {
  line-height: 1.5;
  color: black;
  margin: 0;
}

.card-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 25px;
  margin-bottom: 25px;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.75);
}

.info {
  height: 15%;
}

.info > div {
  display: inline-block;
}

.dots {
  float: right;
  margin-right: 30px;
  margin-top: 10px;
}

.dots > img {
  height: 40px;
  width: 25px;
}

.name {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: bolder;
}

.image {
  width: 100%;
  height: 40vh;
}

.image > img {
  height: 100%;
  width: 100%;
}

.details {
  height: 12%;
}

.details > div {
  display: inline-block;
}

.share > img,
.like > img {
  height: 40px;
  width: 40px;
  margin-left: 20px;
  margin-top: 5px;
}

.likeCounts {
  margin-left: 20px;
  color: #3a3b3c;
}

.description {
  font-size: 18px;
  margin-left: 20px;
  margin-top: -15px;
  font-weight: bolder;
}

.date {
  float: right;
  margin-right: 30px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}

.container{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header {
  height: 100px;
  border: 1px solid grey;
}

.header > div {
  display: inline-block;
}

img {
  height: 70px;
  width: 70px;
}

.txtHeader {
  position: absolute;
  top: 5px;
  margin-left: 10px;
  font-size: 56px;
  color: blue;
}

.camera {
  float: right;
  margin-right: 100px;
  cursor: pointer;
}

.camera > a {
  height: 100%;
  width: 100%;
}

.camera img {
  height: 100px;
  width: 140px;
}

.circle {
  margin-left: 100px;
  margin-top: 15px;
}

a {
  text-decoration: none;
}

.btnWrapper {
  margin-left: 120px;
  margin-top: -30px;
}

.land-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.land-image {
  width: 50%;
  height: 70%;
}

.land-image > img {
  width: 100%;
  height: 100%;
}

#landBtn {
  padding: 3px 12px;
  font-size: 28px;
  background-color: #fff;
  border: 2px solid green;
  color: green;
  border-radius: 10px;
}

#landBtn:hover {
  cursor: pointer;
  background-color: greenyellow;
  color: red;
}

.txtLand {
  height: 100%;
  margin-left: 50px;
  margin-top: 50px;
}

.txtLand p {
  font-size: 42px;
  color: green;
}

.form-wrapper {
  display: flex;
  justify-content: center;
  height: 50vh;
}

.form {
  margin-top: 3%;
  width: 35%;
  height: 60%;
  border: 1px solid #cccccc;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  margin: 5px 30px;
}

form input {
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 25px;
}

input[type='file'] {
  width: 100%;
}

::-webkit-file-upload-button {
  height: 100%;
  border: none;
  cursor: pointer;
  background-color: #b9b9b9;
}

.author {
  display: flex;
  justify-content: space-between;
}

.author input {
  width: 35%;
}

.desc input {
  width: 100%;
}

.post {
  display: flex;
  justify-content: center;
}

.post button {
  margin-bottom: 10px;
  width: 100px;
  color: gray;
  font-size: larger;
}

form button {
  height: 27px;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 5px;
}

