.card {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 25px;
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.75);
}

.info {
  height: 15%;
}

.info > div {
  display: inline-block;
}

.dots {
  float: right;
  margin-right: 30px;
  margin-top: 10px;
}

.dots > img {
  height: 40px;
  width: 25px;
}

.name {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: bolder;
}

.image {
  width: 100%;
  height: 40vh;
}

.image > img {
  height: 100%;
  width: 100%;
}

.details {
  height: 12%;
}

.details > div {
  display: inline-block;
}

.share > img,
.like > img {
  height: 40px;
  width: 40px;
  margin-left: 20px;
  margin-top: 5px;
}

.likeCounts {
  margin-left: 20px;
  color: #3a3b3c;
}

.description {
  font-size: 18px;
  margin-left: 20px;
  margin-top: -15px;
  font-weight: bolder;
}

.date {
  float: right;
  margin-right: 30px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}
