.btnWrapper {
  margin-left: 120px;
  margin-top: -30px;
}

.land-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.land-image {
  width: 50%;
  height: 70%;
}

.land-image > img {
  width: 100%;
  height: 100%;
}

#landBtn {
  padding: 3px 12px;
  font-size: 28px;
  background-color: #fff;
  border: 2px solid green;
  color: green;
  border-radius: 10px;
}

#landBtn:hover {
  cursor: pointer;
  background-color: greenyellow;
  color: red;
}

.txtLand {
  height: 100%;
  margin-left: 50px;
  margin-top: 50px;
}

.txtLand p {
  font-size: 42px;
  color: green;
}
